import React, { useEffect, useState } from "react";
import { withAuth } from "@cdk-prod/fortellis-auth-context";
import { useHistory, useParams } from "react-router-dom";
import { Button, CardBody, CardHeader, CardWrapper, Loader } from "cdk-radial";
import ContractedBundlesGrid from "./ContractedBundlesGrid";
import ContractedApisGrid from "./ContractedApisGrid";
import ContractedApisReview from "./ContractedApisReview";
import "./styles.css";
import axios from "axios";
import config from "../config/app.conf.json";
import { Toast, TOAST_POSITIONS, TOAST_VARIANTS } from 'cdk-radial'

const ContractManagement = ({ auth }) => {
  const history = useHistory();
  const [orgName, setOrgName] = useState("");
  const { orgId } = useParams();
  const { accessToken } = auth;
  const [isLoading, setIsLoading] = useState(true);
  const [isContractApis, setIsContractApis] = useState(false);
  const [contractedApis, setContractedApis] = useState([]);
  const [contractedBundles, setContractedBundles] = useState([]);
  const [selectedBundles, setSelectedBundles] = useState([]);
  const [selectedApis, setSelectedApis] = useState([]);
  const [selectedApisFromBundles, setSelectedApisFromBundles] = useState([]);
  const [apisFromContractedBundles, setApisFromContractedBundles] = useState(
    []
  );
  const [showToast,setShowToast]=useState(false);
  const [toastType,setToastType]=useState('')
  const [toastMessage,setToastMessage]=useState('');
  const removeToast = () => {
    setShowToast(false)
   
}
  const [selectedFields, setSelectedFields] = useState(false);

  const renderOrgId = () => {
    return (
      <>
        <b className="contract-management__org-id-title">Org ID: </b>
        <span className="contract-management__org-id">{orgId}</span>
      </>
    );
  };
  const renderOrgName = () => {
    return <span className="contract-management__org-name">{orgName}</span>;
  };
  useEffect(() => {
    const disable_apis = Array.from(
      new Set(selectedBundles.flatMap((bundle) => bundle.apis))
    );
    setSelectedApisFromBundles(disable_apis);
  }, [selectedBundles]);
  useEffect(() => {
    const disable_apis = Array.from(
      new Set(contractedBundles.flatMap((bundle) => bundle.apis))
    );
    setApisFromContractedBundles(disable_apis);
  }, [contractedBundles]);

  useEffect(() => {
    const fetchContractsAndOrganization = async () => {
      try {
        // Fetch contracts data
        const contractsResponse = await axios.get(
          `${config.api.api_gateway_url}/admin/contracts?orgId=${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        const contractApis = contractsResponse.data.items.filter(
          (a) => a.contractType !== "bundle"
        );
        const contractedBundles = contractsResponse.data.items.filter(
          (a) => a.contractType === "bundle"
        );

        setContractedApis(contractApis);
        setContractedBundles(contractedBundles);

        // Fetch org data
        const orgResponse = await axios.get(
          `${config.api.organizations_uri}/${orgId}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        setOrgName(orgResponse?.data?.name);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    if (accessToken) {
      fetchContractsAndOrganization();
    }
  }, [accessToken, orgId, isLoading]);

  return (
    <>
      {!isLoading ? (
        <div className="contract_management">
          <div className="contract_management_navigation_buttons">
            <Button
              dataTestId="contract-management__nav-button--home"
              iconColor="primary"
              iconSize={24}
              onClick={() => history.push("/")}
              size="standard"
              text="Back to Home"
              variant="primary"
              className="contract-management__nav-button--home"
            />
            <Button
              dataTestId="contract-management__nav-button--search-orgs"
              iconColor="primary"
              iconSize={24}
              onClick={() => history.push("/org-management")}
              size="standard"
              text="Back to Search Orgs"
              variant="primary"
              className="contract-management__nav-button--search-orgs"
            />
          </div>

          <div className="contract-management__card">
            <CardWrapper className="contract-management__card-wrapper">
              <div className="contract-management__card-content">
                <CardBody>
                  <CardHeader
                    className="contract-management__card-header"
                    subtitle={renderOrgId()}
                    title={renderOrgName()}
                  />
                  {isContractApis ? (
                    <div>
                      {selectedFields ? (
                        <div>
                          <div className="review_button_container">
                            <b>
                              Confirm your selected organization mapped to
                              Bundle/API{" "}
                            </b>
                          </div>
                          <div>
                            <ContractedBundlesGrid
                             auth={auth}
                             isLoading={true}
                             setIsLoading={setIsLoading}
                             setShowToast={setShowToast}
                             setToastMessage={setToastMessage}
                             setToastType={setToastType}
                              contractedBundles={contractedBundles}
                              selectedFields={selectedFields}
                              selectedBundles={selectedBundles}
                            />
                            <ContractedApisGrid
                             auth={auth}
                             isLoading={true}
                             setIsLoading={setIsLoading}
                             setShowToast={setShowToast}
                             setToastMessage={setToastMessage}
                             setToastType={setToastType}
                              contractedApis={contractedApis}
                              selectedApis={selectedApis}
                              selectedFields={selectedFields}
                            />
                          </div>
                        </div>
                      ) : (
                        <div>
                          <ContractedApisReview
                            auth={auth}
                            contractedApis={contractedApis}
                            contractedBundles={contractedBundles}
                            setSelectedBundles={setSelectedBundles}
                            selectedBundles={selectedBundles}
                            setSelectedApis={setSelectedApis}
                            selectedApis={selectedApis}
                            selectedApisFromBundles={selectedApisFromBundles}
                            apisFromContractedBundles={
                              apisFromContractedBundles
                            }
                          />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <ContractedBundlesGrid
                        contractedBundles={contractedBundles}
                        auth={auth}
                        isLoading={true}
                        setIsLoading={setIsLoading}
                        setShowToast={setShowToast}
                        setToastMessage={setToastMessage}
                        setToastType={setToastType}
                        selectedFields={selectedFields}
                        selectedBundles={selectedBundles}
                      />
                      <ContractedApisGrid 
                        contractedApis={contractedApis}
                        auth={auth}
                        isLoading={true}
                        setIsLoading={setIsLoading}
                        setShowToast={setShowToast}
                        setToastMessage={setToastMessage}
                        setToastType={setToastType}
                        selectedApis={selectedApis}
                        selectedFields={selectedFields}
                         />
                    </div>
                  )}
                </CardBody>
              </div>

              {isContractApis ? (
                <div>
                  {selectedFields ? (
                    <div className="contract-review__card-footer">
                      <Button
                        dataTestId="contract-review-back-button"
                        iconColor="primary"
                        iconSize={24}
                        onClick={() => {}}
                        size="standard"
                        text="Back"
                        variant="secondary"
                        className="contract-management__add-apis-button"
                      />
                      <Button
                        dataTestId="review-contract-apis-button"
                        iconColor="primary"
                        iconSize={24}
                        onClick={() => {
                          setSelectedFields(true);
                        }}
                        size="standard"
                        text="Contract APIs"
                        variant="primary"
                        isDisabled={
                          selectedApis.length === 0 &&
                          selectedBundles.length === 0
                        }
                        className="contract-management__add-apis-button"
                      />
                    </div>
                  ) : (
                    <div className="contract-review__card-footer">
                      <Button
                        dataTestId="contract-management__cancel-button"
                        iconColor="primary"
                        iconSize={24}
                        onClick={() => {
                          setIsContractApis(false);
                          setSelectedBundles([]);
                          setSelectedApis([]);
                        }}
                        size="standard"
                        text="Cancel"
                        variant="secondary"
                        className="contract-management__add-apis-button"
                      />
                      <Button
                        dataTestId="contract-management__review-button"
                        iconColor="primary"
                        iconSize={24}
                        onClick={() => {
                          setSelectedFields(true);
                        }}
                        size="standard"
                        text="Review"
                        variant="primary"
                        isDisabled={
                          selectedApis.length === 0 &&
                          selectedBundles.length === 0
                        }
                        className="contract-management__add-apis-button"
                      />
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  <div className="contract-management__card-footer">
                    <Button
                      dataTestId="contract__add-apis-select-button"
                      iconColor="primary"
                      iconSize={24}
                      onClick={() => {
                        setIsContractApis(true);
                      }}
                      size="standard"
                      text={
                        contractedApis.length > 0 ||
                        contractedBundles.length > 0
                          ? "Select APIs"
                          : "Add contracted APIs"
                      }
                      variant="primary"
                      className="contract-management__add-apis-button"
                    />
                  </div>
                </div>
              )}
            </CardWrapper>
          </div>
        </div>
      ) : (
        <Loader
          data-testid="manage_contract_apis_loader"
          label="Loading..."
          variant="inline"
        />
      )}
      <div>
      {!!showToast && (
            <Toast
              actions={[
                {
                  onClick: removeToast,
                  text: "Dismiss",
                },
              ]}
              position={TOAST_POSITIONS.FIXED}
              variant={toastType==="Positive"?TOAST_VARIANTS.POSITIVE:TOAST_VARIANTS.NEGATIVE}
              onVisibilityDurationEnd={removeToast}
              content={toastMessage}
              id="bundle-create-toast"
            />
          )}
      </div>
    </>
  );
};

const ManageContractedApis = ({ auth }) =>
  auth.isAuthenticated ? <ContractManagement auth={auth} /> : <div></div>;
export default withAuth(ManageContractedApis);
